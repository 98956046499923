<template>
  <div class="job-detail">
    <JobWantedHead />
    <section class="job-detail__main">
      <header class="job-info flex">
        <div class="job-info__left flex-1">
          <div class="update-time mb-1">
            <i class="el-icon el-icon-time"></i>
            {{ jobInfo.created }}
          </div>
          <div class="title flex">
            <h4 class="job-name">{{ jobInfo.job_title }}</h4>
            <span class="salary flex-1 ml-1">
              {{ jobInfo.job_salary }}·{{ jobInfo.job_salary_constitute }}
            </span>
          </div>
          <div class="job-info-bottom">
            <span class="mr-1">
              <i class="el-icon el-icon-location"></i>
              {{ jobInfo.job_city }} {{ jobInfo.job_district }}
              {{ jobInfo.job_street }}</span
            >
            <span class="mr-1">
              <i class="el-icon el-icon-suitcase-1"></i>
              {{ jobInfo.job_age }}</span
            >
            <span class="mr-1">
              <i class="el-icon el-icon-s-check"></i>
              {{ jobInfo.job_education }}</span
            >
          </div>
        </div>
        <div class="job-info__right flex-col">
          <el-button type="warning" @click="handleChat">立即沟通</el-button>
          <el-button type="primary" @click="onSendCV">投递简历</el-button>
        </div>
      </header>
      <el-row>
        <el-col
          class="job-detail__center"
          :span="18"
          style="padding-right: 20px"
        >
          <section class="card">
            <h3 class="title">职位描述</h3>
            <div class="mt-1 mb-1">
              <span
                class="job-tag mr-1"
                v-for="(item, index) in jobTags"
                :key="index"
                >{{ item }}</span
              >
            </div>
            <div>
              <p>{{ jobInfo.job_describe }}</p>
            </div>
          </section>
          <section class="card">
            <h3 class="title mb-1">公司介绍</h3>
            <div>
              <p>{{ jobInfo.chain_name }}</p>
            </div>
          </section>
        </el-col>
        <el-col :span="6" class="info-card">
          <header class="info-card-header">公司基本信息</header>
          <div class="info-card-content">
            <div class="top">
              <el-image :src="jobInfo.pic" alt="" />
              <span class="ml-1">{{ jobInfo.chain_name }}</span>
            </div>
            <div class="center">
              <div v-if="jobInfo.job_financing">
                <i class="mr-1 el-icon el-icon-coin"></i
                >{{ jobInfo.job_financing }}
              </div>
              <div v-if="jobInfo.job_scale">
                <i class="mr-1 el-icon el-icon-user"></i>{{ jobInfo.job_scale }}
              </div>
              <div v-if="jobInfo.job_industry">
                <i class="mr-1 el-icon el-icon-office-building"></i
                >{{ jobInfo.job_industry }}
              </div>
            </div>
            <div class="bottom mt-1 ta-c">
              <el-button type="primary" size="small" @click="handleDetail"
                >查看详情</el-button
              >
            </div>
          </div>
        </el-col>
      </el-row>
    </section>
  </div>
</template>

<script>
import JobWantedHead from "@/components/JobWantedHead.vue";
import { getJobDetails, personalInsertCommunicate } from "@/request/api";
export default {
  name: "JobDetail",
  components: {
    JobWantedHead,
  },
  data() {
    return {
      jobInfo: {
        // shop_user_job_id: "2",
        // job_title: "前端开发工程师",
        // job_age: "1-3年",
        // job_education: "本科",
        // job_salary: "8-12k",
        // job_salary_constitute: "13薪",
        // job_duration: "全职",
        // is_enable: "1",
        // is_del: "0",
        // expiration_time: "2024-07-23 13:42:24",
        // job_describe: "我是职位描述",
        // job_keyword: "关键词1,关键词2,关键词3",
        // user_ai_chain_id: "2846",
        // job_city: "杭州市",
        // job_district: "滨江区",
        // job_street: "长河街道",
        // created: "2024-07-23 17:55:52",
        // chain_name: "测试公司",
        // pic: "https://bmbshopping.oss-cn-hangzhou.aliyuncs.com/shopgoods/0.5026293497064056357703e5de8055d287d273d90423f86.png",
        // job_financing: null,
        // job_scale: null,
        // job_industry: null,
      },
    };
  },
  created() {
    getJobDetails(this.$route.query.shop_user_job_id).then((res) => {
      if (res.data.code === "1000") {
        this.jobInfo = res.data.data;
        console.log(this.jobInfo);
      }
    });
    // this.jobInfo = this.$route.query;
    console.log();
  },
  computed: {
    jobTags() {
      if (this.jobInfo.job_keyword) {
        return this.jobInfo.job_keyword.split(",");
      } else {
        return [];
      }
    },
  },
  methods: {
    sendExit() {
      this.$emit("update-value");
    },
    // 除以1000,取整
    divSalary(count) {
      return Math.round(count / 1000);
    },
    goHome() {
      // 返回首页
      this.$router.push("/").catch((e) => {
        console.error(e);
      });
    },
    // 投递简历
    onSendCV() {
      let params = {
        shop_user_job_id: this.jobInfo.shop_user_job_id,
        is_send: 1,
      };
      personalInsertCommunicate(params)
        .then((res) => {
          if (res.status === 200) {
            this.$message({
              message: "成功投递！",
              type: "success",
            });
            this.handleChat();
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    // 立即沟通
    handleChat() {
      this.$router
        .push({
          path: "/workChat",
          query: { shop_user_job_id: this.$route.query.shop_user_job_id },
        })
        .catch((e) => {
          console.error(e);
        });
    },
    handleDetail() {
      console.log("/companyDetail", this.jobInfo.user_ai_chain_id);
      // 跳转到公司详情页
      this.$router
        .push({
          path: "/companyDetail",
          query: { user_ai_chain_id: this.jobInfo.user_ai_chain_id },
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.recommend-head {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .head-right {
    width: 300px;
    height: 100%;
    padding-right: 1%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right-title {
      width: 60%;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #2a6ee9;
      font-weight: bold;

      .title {
        border-bottom: 1px solid #2a6ee9;
        cursor: pointer;
        font-weight: inherit;
        color: inherit;
      }
    }

    .exit {
      width: 70px;
      cursor: pointer;
    }
  }

  .head-left {
    display: flex;
    align-items: center;

    .head-icon {
      width: 40px;
      margin-right: 6px;
    }

    .head-title {
      font-size: 18px;
      color: #7a7c7e;
    }
  }
}

.job-detail {
  height: 100%;
  overflow: auto;
  .el-button {
    border-radius: 6px;

    &.el-button--warning {
      background: #f4a933;
    }

    &.el-button--primary {
      background: #3e83ff;
    }

    &:hover {
      opacity: 0.8;
    }
  }
  .job-detail__main {
    // width: 1280px;
    margin: 0 auto;
  }

  .job-info {
    margin-bottom: 20px;
    padding: 1em 3em;
    background: #deebfc;
    border-radius: 10px;

    .job-info__left {
      .title {
        margin: 1em 0;

        .job-name {
          font-size: 20px;
          margin-right: 2em;
        }

        .salary {
          color: #f4a933;
          font-weight: 600;
          font-size: 20px;
        }
      }
    }

    .job-info-bottom {
      font-size: 12px;

      .el-icon {
        color: #3e83ff;
        margin-right: 2px;
      }
    }

    .job-info__right {
      .el-button {
        margin: 0;

        & + .el-button {
          margin-top: 10px;
        }
      }
    }
  }

  .job-detail__center {
    .card {
      padding: 1em 3em;
      background: #fff;
      border-radius: 16px;
      margin-bottom: 20px;

      p {
        line-height: 2;
      }
    }
  }

  //  右侧公司信息
  .info-card {
    .top {
      .el-image {
        width: 60px;
        height: 60px;
        border-radius: 16px;
        vertical-align: -10px;
      }

      font-size: 18px;
    }

    .center {
      .el-icon {
        margin-top: 1em;
        color: #3e83ff;
      }
    }
  }
}
</style>
